var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexbox employee--container"},[(_vm.loading)?_c('Loading'):_c('div',[_c('table',{staticClass:"table employee_table"},[_c('thead',[_c('th',[_vm._v("Naam")]),_c('th',[_vm._v("Tussenvoegsel")]),_c('th',[_vm._v("Achternaam")]),_c('th',[_vm._v("Telefoonnummer")]),_c('th',[_vm._v("Intern")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Functie")]),(
            _vm.checkroles(
              ['it', 'hr'],
              _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles
            )
          )?_c('th',[_vm._v(" Activeren ")]):_vm._e()]),_c('tbody',_vm._l((_vm.employees),function(item,key){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.voornaam))]),_c('td',[_vm._v(_vm._s(item.tussenvoegsel))]),_c('td',[_vm._v(_vm._s(item.achternaam))]),_c('td',[_vm._v(_vm._s(item.telefoonnummer))]),_c('td',[_vm._v(_vm._s(item.intern))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.functie))]),(
              _vm.checkroles(
                ['it', 'hr'],
                _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles
              )
            )?_c('td',[_c('button',{staticClass:"employee_activate",attrs:{"type":"button"},on:{"click":function($event){return _vm.activateEmployee(item, key)}}},[_vm._v(" Activeren ")])]):_vm._e()])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }