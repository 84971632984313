<template>
  <div class="flexbox employee--container">
    <Loading v-if="loading" />
    <div v-else>
      <table class="table employee_table">
        <thead>
          <th>Naam</th>
          <th>Tussenvoegsel</th>
          <th>Achternaam</th>
          <th>Telefoonnummer</th>
          <th>Intern</th>
          <th>Email</th>
          <th>Functie</th>
          <th
            v-if="
              checkroles(
                ['it', 'hr'],
                msalInstance.getAllAccounts()[0].idTokenClaims.roles
              )
            "
          >
            Activeren
          </th>
        </thead>
        <tbody>
          <tr v-for="(item, key) in employees" :key="item.id">
            <td>{{ item.voornaam }}</td>
            <td>{{ item.tussenvoegsel }}</td>
            <td>{{ item.achternaam }}</td>
            <td>{{ item.telefoonnummer }}</td>
            <td>{{ item.intern }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.functie }}</td>
            <td
              v-if="
                checkroles(
                  ['it', 'hr'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
              "
            >
              <button
                type="button"
                class="employee_activate"
                @click="activateEmployee(item, key)"
              >
                Activeren
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  components: { Loading },
  data: () => ({
    employees: [],
    loading: true,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(`inactive-employees`, "GET").then(({ employees }) => {
        this.employees = employees;
        this.loading = false;
      });
    },

    activateEmployee(employee) {
      if (confirm("Gebruiker activeren?")) {
        const data = {
          id: employee.id,
          first_name: employee.voornaam,
          preposition: employee.tussenvoegsel,
          last_name: employee.achternaam,
          functie: employee.functie,
          telefoonnummer: employee.telefoonnummer,
          intern: employee.intern,
          email: employee.email,
          permission: employee.permission,
          isactive: 1,
        };
        request("employee", "PATCH", data).then(() => {
          this.$store.dispatch("addNotification", {
            message: "Medewerker geactiveerd",
            type: "success",
          });
          this.loading = true;
          this.getData();
        });
      }
    },
  },
};
</script>
